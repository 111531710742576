import React from 'react';
import Hashh from "../assets/images/Hashh.png";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";


const Welcome = () => {
  return (
    <div >
      <div className='welcome-main'> 
        <div className='welcome-logo-parent'><img src={Hashh} alt="logo" className="image-fluid" /></div>
        <div>
          <div className='welcome-greet'>Welocome to</div>
          <div className='welcome-heading'>HASHh Industry Dashboard </div>
          <div className='welcome-login-parent'><Link to='/Login' style={{ color: 'white'}}><p className='welcome-login'>Login <ArrowRightOutlined style={{color:'white',textAlign:'right'}} /></p></Link></div>
        </div>
        
      </div>
      <footer className='welcome-footer'>
        <p className="welcome-copyright">
          Copyright © 2023 by <a href="#pablo">HASHh Automations</a>.{" "}
        </p>
      </footer>
    </div>
  )
}

export default Welcome
