/*!
*/

import {
  Row,
} from "antd";

function Routines() {
  
  return (
    <>
      <Row gutter={[24, 0]}>
      </Row>
    </>
  );
}

export default Routines;
