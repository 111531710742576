/*!
*/
import {
  Row,
  Card,
  Col
} from "antd";

import {
  MenuOutlined,
} from "@ant-design/icons";

import {UserService} from '../services/user_profile_api';
import { useState, useEffect } from "react";
import { NavLink, Route, useLocation, useHistory } from "react-router-dom";
import Location from "./Location";
import { AiFillThunderbolt } from "react-icons/ai";
import { BiRadioCircleMarked } from "react-icons/bi";
import { CiTempHigh } from "react-icons/ci";

function Property() {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const [sites, setSites] = useState(null);
  const [property, setProperty] = useState(null);
  const [propertyId, setPropertyId] = useState(null);
  const navigate = useHistory();
  const [isHovering, setIsHovering] = useState(false);

  const header = {
    background: "#8C8F9C",
    color:"white",
    width: "270px",
    height: "150px",
    margin: "10px",
    borderRadius: "20px",
    boxSizing: "borderBox",
    boxShadow: isHovering ? "5px 3px 5px #c5c5c5":"",
    border: `1.7px solid ${"#8C8F9C"}`,
    cursor:"pointer",
    position: "relative",
    transition: "1000ms",
    AnimationDuration:"3s",
    AnimationIterationCount: "1",
    AnimationDirection: "reverse",
    appearance: "auto",
    AnimationName: "bounce",
    transition: "box-shadow .20s ease-in-out",
    transition: "background-position 0.5s ease-in-out",
  };


  async function getLocations(propertyid) {
    try {
      console.log(propertyid);
      const result = await UserService.getLocations(propertyid);
      console.log(result);
      if(result.sites){
        setSites(
          result.sites.map(el => {
            return {
              label: el.name,
              locations: el.locations,
            };
          }),
        );
      }
    } catch (error) {
      console.log('error...', error);
    }
  }

  useEffect(() => {
    async function getProperty(){
      try {
        const result = await UserService.getProperty('varanasi');
        console.log(result);
        if(result){
          setProperty(result[0].property.name);
          setPropertyId(result[0].property.id);
          getLocations(result[0].property.id);
        }
      } catch (error) {
        console.log('error...', error);
      }
    }
    getProperty();
  },[]);

  function goToLocation(site,location){
    console.log("change!!");
    let props={
      propertyId: propertyId,
      siteName : site,
      locationName : location
    };
    navigate.push ('Location',{params:props});
  }

  return (
    <>
      <div className="tabled">
        <Row gutter={[16, 0]}>
          {sites ?
            sites.map((c, index) => (
              <Card 
                title={c.label} 
                style={{ width: '100%', marginBottom:"12px"}} 
                extra={<a href="#">Edit</a>}>
                 <Row gutter={[16,16]}>
                    { c.locations ?
                      c.locations.map((key,index) => (
                        <Col gutter={6}>
                          <div style={header} onClick={()=>goToLocation(c.label,key.name)}>
                            {/* <Card
                              hoverable 
                              title={key.name} 
                              style={{textAlign: 'center', padding: '12px'}} >
                            </Card> */}
                            <div>
                              <div>
                                <p style={{float:"left",fontSize:"20px",paddingTop:"20px",paddingLeft:"20px"}}> {key.name}  <br/> <p style={{fontSize:"10px",paddingTop:"50px",paddingBottom:"20px"}}><AiFillThunderbolt style={{fontSize:"15px",color:"#ffc53d"}}/>300kw</p> </p>  
                              </div> 
                              <div> 
                                <p style={{float:"right",fontSize:"14px",paddingTop:"27px",paddingRight:"20px"}}> </p>
                              </div>  
                            </div>
                          </div>
                        </Col>
                    )):null}
                  </Row>
              </Card>
          )): null}
        </Row>
      </div>
    </>
  );
}

export default Property;
