const ThemeColor = {
    white: 'white',
    whitesmoke: '#e3e3e3',
    black: 'black',
    red: 'red',
    green: 'green',
    alertSuccess: 'rgb(71,177,116)',
    alertError: 'rgb(255,85,85)',
    overlay: 'rgba(0,0,0,0.4)',
    hamColor: 'white',
    header: 'black',
    slateBlue: 'rgba(114,62,216, 0.5)',
    activeText: 'white',
    inactiveText: 'rgba(255, 255, 255, 0.6)',
    blueRibbon: '#0a62f1',
    assistantHead: 'rgb(66, 133, 244)',
    bulbPrimary: 'rgba(249, 229, 123, 1)',
    bulbSecondary: 'rgba(255, 204, 59, 1)',
    socketSecondary: '#4c64df',
    socketPrimary: '#526eff',
    fanSecondary: '#7448e0',
    fanPrimary: '#8d48fe',
    hashhPrimary: 'rgb(114,62,216)',
    hashhPrimaryHalf: 'rgba(114,62,216, 0.5)',
    hashhPrimaryQuarter: 'rgba(114,62,216, 0.25)',
    switchPrimary: '#FF4B2B',
    switchSecondary: '#FF416C',
    switchPrimaryIndustry: '#6e828e',
    switchSecondaryIndustry: 'white',
    doorPrimary: '#00F260',
    doorSecondary: '#0575E6',
    pirPrimary: '#8d48fe',
    pirSecondary: '#0575E6',
    gasPrimary: '#8d48fe',
    gasSecondary: '#0575E6',
    smokePrimary: '#8d48fe',
    smokeSecondary: '#0575E6',
    off: '#fe4550',
    on: '#47e888',
    quickActions: ['#f06777', '#51d5b0', '#95a0fa'],
    lightgray: '#bdbdbd',
    // swtiches
    bulbPrimary1: 'rgba(252, 224, 84, 1)',
    bulbSecondary1: 'rgba(255, 204, 59, 1)',
    bulbPrimaryIndustry: '#6e828e',
    bulbSecondaryIndustry: '#6e828e',
    socketSecondary1: 'rgba(63, 92, 238, 1)',
    socketPrimary1: 'rgba(51, 85, 255, 1)',
    socketPrimaryIndustry: '#6e828e',
    SocketSecondaryIndustry: '#6e828e',
    fanSecondary1: 'rgba(90, 33, 232, 1)',
    fanPrimary1: 'rgba(90, 33, 232, 1)',
    switchPrimary1: 'rgba(224, 0, 0, 1)',
    switchSecondary1: 'rgba(224, 0, 0, 1)',
    switchPrimaryIndustry: '#6e828e',
    switchSecondaryIndustry: '#6e828e',
    switchTextColorIndustry: '#e6eaec',
};

export default ThemeColor;