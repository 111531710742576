import axios from 'axios';
import { UserService } from '../services/user_profile_api';
import { Keycloaktokens, User } from '../storage/store';

/**
 * Create an Axios Client with defaults
 */
const axiosClient = axios.create({
  baseURL: 'https://api-industry.hashh.io/api/v1/',
  timeout: 30000,
});

/**
 * Request Wrapper with default success/error actions
 */
const Api = async (
  config = {method: 'GET', data: {}, url: ''},
  shouldAppendToken = true
) => {
  // Success
  const onSuccess = response => {
    console.log('response', JSON.stringify(response.data, null, 2));
    console.log(response.data.id);
    User.setPropertyId(response.data.id);
    return Promise.resolve(response.data);
  };

  // Error
  const onError = error => {
    console.log('Request Failed:', error);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.log('Data:', error.response.data);
      return Promise.reject(error.response.data);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.log('Error Message:', error.message);
      return Promise.reject(error.message);
    }
  };

  // Append headers
  let headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  };

  if (shouldAppendToken) {
    try {
      const token = Keycloaktokens.getToken();
      headers["Authorization"] = `Bearer ${token}`;
      // console.log(token);
    } catch (error) {
      // Token is not found
      console.log("Error Message:", error.message);

      return Promise.reject(error.message);
    }
  }
  // Set headers
  axiosClient.defaults.headers = headers;

  console.log('Request Headers!', axiosClient.defaults.headers);
  console.log('Request Configurations!', config);

  return axiosClient(config)
    .then(onSuccess)
    .catch(onError);
};

export default Api;

