/*!
*/
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Property from "./pages/Property";
import Routine from "./pages/Routine";
import RuleEngine from "./pages/RuleEngine";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import Location from "./pages/Location";
import SensorDetail from "./components/device/SensorDetail";
import SwitchDetail from "./components/device/SwitchDetails";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Welcome from "./pages/Welcome";
import Login from "./auth/LoginView";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={Welcome}/>
        <Route path="/sign-up" exact component={SignUp} />
        <Route path="/sign-in" exact component={SignIn} />
        <Route path="/welcome" component={Welcome}/>
        <Route exact path="/Login" component={Login} />
        <Main>
          <Route exact path="/sign-in" component={SignIn} />
          <Route exact path="/dashboard" component={Home} />
          <Route exact path="/property" component={Property} />
          <Route exact path="/routine" component={Routine} />
          <Route exact path="/ruleEngine" component={RuleEngine} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path='/location' component={Location} />
          <Route exact path='/sensorDetail' component={SensorDetail} />
          <Route exact path='/switchDetail' component={SwitchDetail} />
        </Main>
        <Redirect from="*" to="/welcome" />
      </Switch>
    </div>
  );
}

export default App;
