/*!
*/
import {
    Row,
    Col,
  } from "antd";

  import {UserService} from '../services/user_profile_api';
  import { useState, useEffect } from "react";
  import { useLocation } from "react-router-dom";
  import SwitchCard from "../components/device/SwitchComponent"
  import SensorCard from "../components/device/SensorComponent";
  
  function Location() {

    const location = useLocation();
    const param = location.state.params;
    const [configDevices, setConfigDevices] = useState(null);
    const [devices, setDevices] = useState(null);
    
    function getLastestate(configDev) {
        async function getLocationDevices(){
          try {
            const lastStates = await UserService.getDevicesByLocation(param.propertyId,param.siteName,param.locationName);
            if(lastStates){
                console.log(lastStates);
                let allLocationDevices = configDev;
                allLocationDevices.forEach((data, index) => {
                    if (
                        lastStates[0].devices[data.applianceId] &&
                        'speed' in lastStates[0].devices[data.applianceId]
                    )   {
                        data.props = {
                          state: lastStates[0].devices[data.applianceId]
                            ? lastStates[0].devices[data.applianceId].state
                            : false,
                          speed: lastStates[0].devices[data.applianceId]
                            ? lastStates[0].devices[data.applianceId].speed
                            : 3,
                          timeStamp: lastStates[0].devices[data.applianceId]
                            ? lastStates[0].devices[data.applianceId].timeStamp
                            : null,
                        };
                    } else if(
                        lastStates[0].devices[data.applianceId] &&
                        'value' in lastStates[0].devices[data.applianceId]
                    ) {
                        data.props = {
                            state: lastStates[0].devices[data.applianceId]
                                ? lastStates[0].devices[data.applianceId].state
                                : false,
                            value: lastStates[0].devices[data.applianceId]
                                ? lastStates[0].devices[data.applianceId].value
                                : '',
                            timeStamp: lastStates[0].devices[data.applianceId]
                                ? lastStates[0].devices[data.applianceId]?.timeStamp
                                : null,
                            ...lastStates[0].devices[data.applianceId],
                        };
                    } else {
                        data.props = {
                            state: lastStates[0].devices[data.applianceId]
                                ? lastStates[0].devices[data.applianceId].state
                                : false,
                            timeStamp: lastStates[0].devices[data.applianceId]
                                ? lastStates[0].devices[data.applianceId]?.timeStamp
                                : null,
                            ...lastStates[0].devices[data.applianceId],
                        };
                    }
                });
                //console.log('DATA:'+JSON.stringify(allLocationDevices));
                const locationDevices = allLocationDevices.filter(data =>{
                    return data.site === param.siteName && data.location === param.locationName
                })
                setDevices(locationDevices);
                //console.log(allLocationDevices);
                // setLocationDevices(Object.values(result[0].devices));
                // let AllLocationDevices = Object.values(result[0].devices);
                // console.log(AllLocationDevices);
                // console.log(result);
                // fromDevice(AllLocationDevices);   
            }
          } catch (error) {
            console.log('error...', error);
            /* by default set all devices default values*/
            let allLocationDevices = configDev;
            const locationDevices = allLocationDevices.filter(data =>{
                return data.site === param.siteName && data.location === param.locationName
            })
            setDevices(locationDevices);
          }
        }
        getLocationDevices();
    };

    useEffect(() => {
        async function getConfig(){
            try{
                const result = await UserService.getConfig(param.propertyId);
                if(result){
                    //console.log(result.devices);
                    setConfigDevices(result.devices);
                    getLastestate(result.devices);
                }
            } catch (error) {
                console.log('error...', error);
            }
        }
        getConfig();
    },[]);

    if(devices){
        return (
            <>
                <div className="tabled">
                    <Row gutter={[16, 16]}>
                        {devices ?
                         devices.map((data,index) => (
                            <Col gutter={6}>
                                { data.deviceType === '0x101' ?
                                    <SensorCard props={data}/>
                                : data.deviceType === '0x01' ?
                                    <SwitchCard props={data}/>
                                :
                                    <SwitchCard props={data}/>
                                }
                            </Col>
                        )) : null}
                    </Row>
                </div>
            </>
          );
    } else{
        return (
            <>
              <div className="tabled">
                <Row gutter={[16, 0]}>
                  <div>
                    <p>No Devices !</p>
                  </div>
                </Row>
              </div>
            </>
        );
    }
    
  }
  
  export default Location;
  