const ApiConfig = {
    baseUrl: 'https://api-industry.hashh.io/api/v1/',
    urls: {
      user: 'user',
      getConfigById: 'config',
      getTimeline: 'logs/date-range',
      getProperty: 'property',
      getLocations: 'config',
      getDeviceTypes: 'config',
      updateState: 'control',
      getLastState: 'laststate',
      getLastStateByLocation: 'laststate',
      registerDevice: 'device',
      deleteDevice: 'device/property',
      routine: 'routine',
      rule: 'rule',
      getKwhByMonth: 'logs/kwhmonth-range',
      getWattsByLastOneHour: 'logs/wattslastonehour-range',
      getKwhByWeek: 'logs/kwhweek-range',
      getMetricsbyDateRange: 'logs/metrics-date-range',
      getLastOneHourMetricsData: 'logs/last-one-hour-metrics',
      getLastOneDayMetricsData: 'logs/last-one-day-metrics',
      getLastOneMonthMetricsData: 'logs/last-one-month-metrics'
    },
  };
  
  export default ApiConfig;
  