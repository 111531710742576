import React, { useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { useState } from "react";
import {MdOutlineDeviceThermostat} from "react-icons/md"
import ThemeColor from "../../assets/theme/ThemeColor";
import SensorDetail from "./SensorDetail";
import "../../assets/styles/main.css";
import "../../assets/styles/responsive.css";
import { User } from "../../storage/store";
import TimeAgo from "../../pages/helpers/TimeAgo";
import { UserService } from '../../services/user_profile_api';

const SensorCard=({props})=>{
  
    const devices = props;
    const navigate = useHistory();
    const [isHovering, setIsHovering] = useState(false);
    const [buttonColor,setButtonColor] = useState(ThemeColor.switchSecondary1);
    const [textColor, setTextColor] = useState(ThemeColor.switchTextColorIndustry);
    const [isHolding, setIsHolding] = useState(false);
    // const PropertyId = User.getPropertyId();
    const [propertyId, setPropertyId] = useState(null);

    useEffect(() => {
        async function getProperty() {
          try {
            const result = await UserService.getProperty('varanasi');
            console.log(result);
            if (result) {
              setPropertyId(result[0].property.id);
            }
          } catch (error) {
            console.log('error...', error);
          }
        }
        getProperty();
      }, []);

    let holdTimer;

    const handleMouseDown = () => {
        holdTimer = setTimeout(() => {
            setIsHolding(true);
            navigate.push('SensorDetail', { data: devices, propertyId: propertyId })
            console.log("On hold!");
        }, 1000);
    };

    const handleMouseUp = () => {
        clearTimeout(holdTimer);
        setIsHolding(false);
    };

    const handlemouseEnter = () =>{
        setIsHovering(true)
    };

    const handlemouseLeave = () =>{
        setIsHovering(false);
    };
      
    const main = {
        width: "auto",
        height: "100%",
        display: "grid",
        margin: "10px",
        borderRadius: "20px",
    }
      
    const header = {
        left: '15px',
        top: '15px',
        background: buttonColor,
        width: "150px",
        height: "180px",
        margin: "10px",
        borderRadius: "20px",
        boxSizing: "borderBox",
        boxShadow: isHovering ? "0px 0px 10px 1px #0006" : "0px 0px 5px 1px #0002",
        border: `1.7px solid ${buttonColor}`,
        cursor: "pointer",
        position: "relative",
        transition: "1000ms",
        AnimationDuration: "3s",
        AnimationIterationCount: "1",
        AnimationDirection: "reverse",
        appearance: "auto",
        AnimationName: "bounce",
        transition: "box-shadow .20s ease-in-out",
        transition: "background-position 0.5s ease-in-out",
    }

    useEffect(() => {
    },[]);
    console.log(props)
    return(
            <div style={header}
                onMouseEnter={handlemouseEnter}
                onMouseLeave={handlemouseLeave}
            onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
                <div className="sensor-container-valueText">
                    <b style={{color: textColor}}>{devices.props.value}&deg;
                        <b style={{fontSize:"30px", color: textColor}}>c</b>
                    </b> 
                    <MdOutlineDeviceThermostat style={{fontSize: '28px',color: textColor}}/><br/>
                </div>
                <div style={{ color: "black" }}>
                <div
                    style={{
                        display: 'flex',
                        color: textColor,
                        marginLeft: "17px",
                        height: '40px',
                        alignItems: 'start',
                        justifyContent: 'start',
                        marginBottom: '10px',
                        marginTop: '15px',
                        overflow: 'hidden',
                    }}
                >
                    <b style={{ fontSize: '1em', alignSelf: 'end' }}>{devices.name}</b>
                </div>
                <div
                    style={{
                        color: textColor,
                        marginLeft: "17px",
                        fontSize: '0.7em',
                        height: '15px',
                        overflow: 'hidden',
                    }}>{devices.site}{" "}&nbsp;|&nbsp;{" "}{devices.location}</div>
            </div>
            <div
                style={{
                    color: textColor,
                    marginLeft: "17px",
                    overflowY: 'hidden',
                    padding: '10px 0px 0px 0px',
                    fontSize: '0.8em',
                    height: '30px',
                }}
            >
                <div>{TimeAgo.getTimeAgo(devices.props.timeStamp)}</div>
            </div>
        </div>
    );
  
  }
  export default SensorCard;
  