import React from 'react'

const EllipsisSpinner = () => {
    return (
        <div style={{
            width:'100%',
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}>
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default EllipsisSpinner