import API from '../api/manager';
import config from '../api/config';

const loginUser = (data) =>{
    return API({
        method: 'GET',
        url: config.baseUrl+config.urls.Userlogin,
        data,
    })
}

const getProperty = (username) => {
    return API({
        method: 'GET',
        url: config.baseUrl+config.urls.getProperty+`/${username}`,
    });
};

const getLocations = id => {
    return API({
      method: 'GET',
      url: config.baseUrl+config.urls.getLocations + '/' + id + '/locations-v2',
    });
};

const getDevicesByLocation = (id, site, location) => {
    return API({
      method: 'GET',
      url: config.baseUrl+config.urls.getLastStateByLocation +`/${id}/site/${site}/location/${location}`,
    });
};

const getDevicesByProperty = (id) => {
  return API({
    method: 'GET',
    url: config.baseUrl+config.urls.getLastState +`/${id}`,
  });
};

const getConfig = id => {
    return API({
        method: 'GET',
        url: config.baseUrl+config.urls.getConfigById + '/' + id,
      });
}

const stateUpdate = (data, propertyId) => {
    return API({
      method: 'POST',
      url: config.baseUrl+config.urls.updateState + `/${propertyId}`,
      data,
    });
};

const getMetricsbyDateRange = (deviceId, dates) => {
    return API({
      method: 'GET',
      url: config.baseUrl+`${config.urls.getMetricsbyDateRange}/${deviceId}/?from=${dates[0]}&to=${
        dates[1]
      }`,
    });
};

const getTimeline = (deviceId, dates) => {
    return API({
      method: 'GET',
      url: config.baseUrl+`${config.urls.getTimeline}/${deviceId}/?from=${dates[0]}&to=${
        dates[1]
      }`,
    });
};

const getLastOneHourMetricsData = (deviceId) => {
    return API({
      method: 'GET',
      url: config.baseUrl+`${config.urls.getLastOneHourMetricsData}/${deviceId}`,
    });
};
  
const getLastOneDayMetricsData = (deviceId) => {
    return API({
      method: 'GET',
      url: config.baseUrl+`${config.urls.getLastOneDayMetricsData}/${deviceId}`,
    });
};

const getLastOneMonthMetricsData = (deviceId) => {
    return API({
      method: 'GET',
      url: config.baseUrl+`${config.urls.getLastOneMonthMetricsData}/${deviceId}`,
    });
};
  
const updateDevice = (data, propertyId) => {
  return API({
    method: 'PUT',
    url: config.baseUrl+`${config.urls.getDeviceTypes}/${propertyId}`+'/appliance',
    data,
  });
};

const fav = (data, propertyId) => {
  return API({
    method: "PUT",
    url: config.urls.getDeviceTypes + `/${propertyId}` + "/favourite",
    data,
  });
};

  
export const UserService = {
    getProperty,
    getLocations,
    loginUser,
    getDevicesByLocation,
    getConfig,
    stateUpdate,
    getMetricsbyDateRange,
    getTimeline,
    getLastOneHourMetricsData,
    getLastOneDayMetricsData,
    getLastOneMonthMetricsData,
    updateDevice,
    fav,
    getDevicesByProperty
};  
