import React, { useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { useState } from "react";
import { BulbOutlined, PoweroffOutlined } from "@ant-design/icons";
import { MdOutlet } from "react-icons/md"
import DeviceSpecific from "../../assets/theme/DeviceSpecificColor"
import "../../assets/styles/main.css";
import "../../assets/styles/responsive.css";
import ThemeColor from "../../assets/theme/ThemeColor";
import { UserService } from '../../services/user_profile_api';
import { User } from "../../storage/store";
import TimeAgo from "../../pages/helpers/TimeAgo";

const SwitchCard = ({ props }) => {

    const devices = props;
    const navigate = useHistory();
    const [isHovering, setIsHovering] = useState(false);
    const [buttonColor, setButtonColor] = useState('white');
    const [textColor, setTextColor] = useState();
    const [iconColor, setIconColor] = useState();
    const [isHolding, setIsHolding] = useState(false);
    // const PropertyId = User.getPropertyId();
    const [propertyId, setPropertyId] = useState(null);

    useEffect(() => {
        async function getProperty() {
          try {
            const result = await UserService.getProperty('varanasi');
            console.log(result);
            if (result) {
              setPropertyId(result[0].property.id);
            }
          } catch (error) {
            console.log('error...', error);
          }
        }
        getProperty();
      }, []);

    let holdTimer;

    const handleMouseDown = () => {
        holdTimer = setTimeout(() => {
            setIsHolding(true);
            navigate.push('switchDetail', { data: devices, propertyId: propertyId })
            console.log("On hold!" + propertyId);
        }, 1000);
    };

    const handleMouseUp = () => {
        clearTimeout(holdTimer);
        setIsHolding(false);
    };

    const handlemouseEnter = () => {
        setIsHovering(true)
    };

    const handlemouseLeave = () => {
        setIsHovering(false);
    };

    function gotoSwitchDetails() {
        console.log('deatils!')
    }

    const main = {
        width: "auto",
        height: "100%",
        display: "grid",
        margin: "10px",
        borderRadius: "20px",
    }

    const header = {
        left: '15px',
        top: '15px',
        background: buttonColor,
        width: "150px",
        height: "180px",
        margin: "10px",
        borderRadius: "20px",
        boxSizing: "borderBox",
        boxShadow: isHovering ? "0px 0px 10px 1px #0006" : "0px 0px 5px 1px #0002",
        border: `1.7px solid ${buttonColor}`,
        cursor: "pointer",
        position: "relative",
        transition: "1000ms",
        AnimationDuration: "3s",
        AnimationIterationCount: "1",
        AnimationDirection: "reverse",
        appearance: "auto",
        AnimationName: "bounce",
        transition: "box-shadow .20s ease-in-out",
        transition: "background-position 0.5s ease-in-out",
    }

    async function toggleSwitch(devices) {
        if (devices.deviceType == "GAS") {
            if (devices.states == false) {
                devices.states = true;
                setButtonColor('#BACDD1');
                setTextColor(ThemeColor.switchTextColorIndustry);
                setIconColor(ThemeColor.switchTextColorIndustry);
            } else {
                setButtonColor('white');
                setTextColor('black');
                setIconColor('black');
                devices.states = false;
            }
        } else {
            if (devices.states == false) {
                devices.states = true;
                setButtonColor(DeviceSpecific.getSwitchColor(devices.deviceType.toLowerCase(), true)[1]);
                setTextColor(ThemeColor.switchTextColorIndustry);
                setIconColor(ThemeColor.switchTextColorIndustry);
            } else {
                setButtonColor('white');
                setTextColor('black');
                setIconColor('black');
                devices.states = false;
            }
        }
        let payload;
        if (devices.states) {
            console.log(devices.states.favourite);
            payload = {
                on: [`${devices.applianceId}`],
                off: [],
                action: {
                    [devices.applianceId]: {
                        id: Number(devices.pin),
                        trait: 701,
                        value: 1,
                        favourite: "true"
                    }
                }
            }
        } else {
            payload = {
                on: [`${devices.applianceId}`],
                off: [],
                action: {
                    [devices.applianceId]: {
                        id: Number(devices.pin),
                        trait: 701,
                        value: Number(devices.states),
                        favourite: "false"
                    }
                }
            }
        }
        console.log(devices)
        console.log(devices.states)
        console.log(navigate.location)
        try {
            console.log('Payload...: ' + JSON.stringify(payload));
            const response = await UserService.stateUpdate(
                payload,
                propertyId,
            );
            console.warn(response);
        } catch (error) {
            console.warn(error);
        }
    }

    useEffect(() => {
        if (devices) {
            if (devices.props.state === true) {
                setButtonColor(DeviceSpecific.getSwitchColor(devices.deviceType.toLowerCase(), devices.props.state)[1]);
                setTextColor(ThemeColor.switchTextColorIndustry);
                setIconColor(ThemeColor.switchTextColorIndustry);
            }
        }
    }, []);

    return (
        <div style={header} className="button"
            onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}
            onMouseEnter={handlemouseEnter}
            onMouseLeave={handlemouseLeave}
            onClick={() => toggleSwitch(devices)} >
            <div className="switch-container-icon">
                {devices ?
                    devices.deviceType === 'SWITCH' ?
                        <PoweroffOutlined style={{ color: iconColor }} />
                        : devices.deviceType === 'LIGHT' ?
                            <BulbOutlined style={{ color: iconColor }} />
                            : devices.deviceType === 'SOCKET' ?
                                <MdOutlet style={{ color: iconColor }} />
                                : devices.deviceType === '0x01' ?
                                    <MdOutlet style={{ color: iconColor }} />
                                    : null
                    : null}
            </div>
            <div style={{ color: "black" }}>
                <div style={{
                    display: 'flex',
                    color: textColor,
                    marginLeft: "17px",
                    height: '40px',
                    alignItems: 'start',
                    justifyContent: 'start',
                    marginBottom: '10px',
                    overflow: 'hidden',
                }}><b style={{ fontSize: '1em', alignSelf: 'end' }}>{devices.name}</b></div>
                <div style={{
                    color: textColor,
                    marginLeft: "17px",
                    fontSize: '0.7em',
                    height: '15px',
                    overflow: 'hidden',
                }}>
                    <div>{devices.site}{" "}&nbsp;|&nbsp;{" "}{devices.location}</div>
                </div>
                <div
                    style={{
                        color: textColor,
                        marginLeft: "17px",
                        overflowY: 'hidden',
                        padding: '10px 0px 0px 0px',
                        fontSize: '0.8em',
                        height: '30px',
                    }}
                >
                    <div>{TimeAgo.getTimeAgo(devices.props.timeStamp)}</div>
                </div>
            </div>
        </div>
    );

}
export default SwitchCard;
