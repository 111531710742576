import React, { useEffect } from "react";
import {
    Card,
    Col,
    Row,
    Typography,
    Timeline,
    Button,
    Drawer,
    Input,
    Form,
    message
  } from "antd";
  import { RiHeart3Fill } from 'react-icons/ri';
import { AiFillSetting,AiFillInfoCircle } from "react-icons/ai";
import { AiOutlineHeart } from "react-icons/ai";
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import { useState } from "react";
import {MdLabel, MdOutlineDeviceThermostat} from "react-icons/md"
import {
    MenuUnfoldOutlined,
    SettingFilled,
    InfoCircleOutlined,
    PoweroffOutlined
  } from "@ant-design/icons";
import ThemeColor from "../../assets/theme/ThemeColor";
// import { Line } from '@ant-design/charts';
import { Line, Column, Area } from '@ant-design/plots';
import {UserService} from '../../services/user_profile_api';
import moment from"moment";
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import "../../assets/styles/main.css";
import "../../assets/styles/responsive.css";
import Paragraph from "antd/lib/typography/Paragraph";

const SwitchDetail=({props})=>{
    const navigate = useHistory();
    const devices = props;
    const params = useLocation();
    const device = params?.state?.data;
    const [data, setData] = useState([]);
    const [reverse, setReverse] = useState(false);
    const [timeLine, setTimeLine] = useState([]);
    const [dates, setDates] = useState([]);
    const { Title, Text } = Typography;
    const [deviceName, setDeviceName] = useState(params.state.data.name);
    const [deviceSiteName, setDeviceSiteName] = useState(params.state.data.site);
    const [deviceLocationName, setDeviceLocationName] = useState(params.state.data.location);
    const [visible, setVisible] = useState(false);
    const [childrenDrawer, setChildrenDrawer] = useState(false);
    const showDrawer = () => setVisible(true);
    const hideDrawer = () => setVisible(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [favourite, setFavourite] = useState(device.favourite ? device.favourite : false) ;
    console.log(favourite)


    const showChildrenDrawer = () => {
        setChildrenDrawer(true);
    };
    
    const onChildrenDrawerClose = () => {
        setChildrenDrawer(false);
    }; 
      
    const [form] = Form.useForm();
      
    const handleDeviceNameChange = (event) => {
        setDeviceName(event.target.value);
    };
      
    const handleDeviceSiteNameChange = (event) => {
        setDeviceSiteName(event.target.value);
    };
    
    const handleDeviceLocationNameChange = (event) => {
        setDeviceLocationName(event.target.value);
    };

    async function updateOnSubmit() {
        const name = deviceName;
        const site = deviceSiteName;
        const location = deviceLocationName;
        const payload = {
          ...device,
          name: name,
          site: site,
          location: location,
        };
        try {
            console.log('STATE UPDATE...: ' + JSON.stringify(payload));
            const response = await UserService.updateDevice(
                payload,
                params.state.propertyId,
            );
            console.warn(response);
            messageApi.open({
                type: 'success',
                content: 'Saved successfully',
                duration: 10,
            });
        } catch (error) {
            console.warn(error  );
            messageApi.open({
                type: 'error',
                content: 'Something Went Wrong',
                duration: 10,
            });
        }
    };



    useEffect(() => {
        var dates=[];
        dates.push(moment.utc().subtract(1, "days").format());
        dates.push(moment.utc().format());
        getMetricsData(dates);
    }, []);
    console.log(device.favourite)
    async function getMetricsData(dates){
        console.log(dates);
        try {
          const result = await UserService.getTimeline(params.state.data.applianceId,dates)
          console.log(result);
          if(result){
            setData(result);
            console.log(result);
          }
        } catch (error) {
          console.log('error...', error);
        }
    } 
       
    const BarChartConfig = {
        data,
        xField: 'time',
        yField: 'status',
        meta: {
            value: {
              alias: 'High Temp',
              nice: true,
            },
            time: {
              tickInterval: 1,
              alias: moment(data).format('MM/DD'),
            },
        },
        brush: {
            enabled: true,
            action: 'highlight',
        },
    };

    function handleDates(dates, index){
        console.log(dates.moment.utc().format());
        console.log(index);
    }

    function onSubmmit(){
        getMetricsData(dates);
    }
    
    async function hearthandle  ()  {
        setFavourite(!favourite);
      const payload = {
        applianceId: params.state.data.applianceId,
        favourite: !favourite,
      };
      try {
        console.log('STATE UPDATE...: ' + JSON.stringify(payload));
        const response = await UserService.fav(
            payload,
            params.state.propertyId,  
        );
        const data = await JSON.stringify(response)
        console.log(data);
        const result = await UserService.getConfig(params.state.propertyId,);
        const filt = [...result.devices].filter(item=>item.applianceId==params.state.data.applianceId);
        setFavourite(filt[0].favourite)
        console.warn(response);
      } catch (error) {
        console.warn(error);
        setFavourite(!favourite);
      }
    }
    console.log(params);

    return(
        <div className="layout-content">
            <Card extra={<>  <RiHeart3Fill
                style={{ color: favourite ? "red" : "grey",fontSize:28,marginRight:"12px" }}
                onClick={hearthandle}
                />
            <AiFillSetting onClick={showDrawer} style={{fontSize:28,marginRight:"2px"}}/> <AiFillInfoCircle onClick={showChildrenDrawer} style={{fontSize:28,marginLeft:"10px",marginRight:"10px"}}/>
                <Drawer
                    title="Setting"
                    className="settings-drawer"
                    mask={true}
                    width={570}
                    onClose={hideDrawer}
                    placement={"right"}
                    visible={visible}
                    footer={<Text style={{color:'gray'}}><center>Manufacturer <br/> HASHh Automations connected <br/>through HASHh Cloud</center></Text>}
                    footerStyle={{fontSize:"18px",fontWeight:"bold",background:"white"}}
                > 
                    <Text style={{fontSize:"16px",marginLeft:"15px",fontWeight:"bold"}}>Name</Text>
                    <Input placeholder="Enter Device Name" value={deviceName}   onChange={handleDeviceNameChange} style={{borderRadius:"8px",padding:"10px",marginBottom:"15px",marginTop:"10px",marginLeft:"10px"}} />
                    <Text style={{fontSize:"16px",marginLeft:"15px",fontWeight:"bold"}}>Site</Text>
                    <Input placeholder="Enter Site" value={deviceSiteName} onChange={handleDeviceSiteNameChange} style={{borderRadius:"8px",padding:"10px",marginBottom:"15px",marginTop:"10px",marginLeft:"10px"}} />
                    <Text style={{fontSize:"16px",marginLeft:"15px",fontWeight:"bold"}}>Location</Text>
                    <Input placeholder="Enter Location" value={deviceLocationName}  onChange={handleDeviceLocationNameChange} style={{borderRadius:"8px",padding:"10px",marginBottom:"15px",marginTop:"10px",marginLeft:"10px"}} />
                    <Text style={{fontSize:"16px",marginLeft:"15px",fontWeight:"bold"}}>Device Type</Text>
                    <Typography style={{borderRadius:"8px"}}>
                        <pre style={{padding:"10px",borderRadius:"8px",width:"520px",marginLeft:"10px"}}>{params.state.data.deviceType === '0x01' ? 'SWITCH' : ''}</pre>
                    </Typography>
                    <Text style={{fontSize:"16px",marginLeft:"15px",fontWeight:"bold"}}>Device IP Voltage</Text>
                    <Typography style={{borderRadius:"8px"}}>
                        <pre style={{padding:"10px",borderRadius:"8px",width:"520px",marginLeft:"10px"}}>{params.state.data.meta.voltage}</pre>
                    </Typography>
                    <Text style={{fontSize:"16px",marginLeft:"15px",fontWeight:"bold"}}>Device IP Current</Text>
                    <Typography style={{borderRadius:"8px"}}>
                        <pre style={{padding:"10px",borderRadius:"8px",width:"520px",marginLeft:"10px"}}>{params.state.data.meta.current}</pre>
                    </Typography>
                    <center> 
                        {contextHolder}
                        <Button type="primary" htmlType="submit" style={{borderRadius:"8px",width:"520px",height:"auto",padding:"10px",marginLeft:"10px",marginTop:"15px"}} onClick={updateOnSubmit}>
                            Save
                        </Button>
                        <Button type="primary" htmlType="submit" style={{borderRadius:"8px",width:"520px",height:"auto",padding:"10px",marginLeft:"10px",marginTop:"15px"}} danger>
                            Delete
                        </Button>
                    </center>
                </Drawer>  
                <Drawer
                    title="Information"
                    className="settings-drawer"
                    mask={true}
                    width={570}
                    onClose={onChildrenDrawerClose}
                    placement={"right"}
                    visible={childrenDrawer}
                    footer={<Text style={{color:'gray'}}><center>Manufacturer <br/> HASHh Automations connected <br/> through HASHh Cloud</center></Text>}
                    footerStyle={{fontSize:"18px",fontWeight:"bold",background:"white"}}
                > 
                    <Row gutter={18}>
                        <Text style={{fontSize:"16px",fontWeight:"bold",marginLeft:"10px",marginRight:"10px",paddingBottom:"10px"}}>Device ID : {params.state.data.deviceId}</Text><br/> 
                    </Row>
                    <Row gutter={18}>
                        <Text style={{fontSize:"16px",fontWeight:"bold",marginLeft:"10px",marginRight:"10px",paddingBottom:"10px"}}>Model : {params.state.data.meta.model}</Text><br/>
                    </Row>
                    <Row gutter={18}>
                        <Text style={{fontSize:"16px",fontWeight:"bold",marginLeft:"10px",marginRight:"10px",paddingBottom:"10px"}}>Build :</Text><br/>
                    </Row>
                    <Row gutter={18}>
                        <Text style={{fontSize:"16px",fontWeight:"bold",marginLeft:"10px",marginRight:"10px",paddingBottom:"10px"}}>Firemware Version  :</Text><br/>
                    </Row>
                    <Row gutter={18}>
                        <Text style={{fontSize:"16px",fontWeight:"bold",marginLeft:"10px",marginRight:"10px",paddingBottom:"10px"}}>Vendor ID   : {params.state.data.meta.vendorId}</Text><br/>
                    </Row>
                    <Row gutter={18}>
                        <Text style={{fontSize:"16px",fontWeight:"bold",marginLeft:"10px",marginRight:"10px",paddingBottom:"10px"}}>Signal Strength(dbm)  :</Text><br/>
                     </Row>
                </Drawer></>}>
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
                        <Card bordered={false} className="criclebox h-full">
                            <div>
                                <Title level={5} style={{ marginBottom: 24 }}>Select Start and End DateTime</Title>
                                {/* <div style={{ marginBottom: 24 }}>
                                    <Paragraph>Start DateTime</Paragraph>
                                    <DateTimePicker onChange={onChange} value={value} />
                                    <Paragraph>End DateTime</Paragraph>
                                    <DateTimePicker onChange={onChange} value={value} />
                                </div> */}
                                {
                                    [1,2].map((data,index) =>{
                                        return(
                                            <div key={index}>
                                                <Paragraph>{index? 'End DateTime' : 'Start DateTime'}</Paragraph>
                                                <DateTimePicker onChange={(value) => handleDates(value,index)} value={dates[index]} />
                                            </div>

                                        );
                                    })
                            
                                }
                                <div style={{marginTop: 24}}>
                                    <Button
                                        type="primary"
                                        className="width-100"
                                        onClick={() => onSubmmit()}
                                    >Apply</Button>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
                        <Card bordered={false} className="criclebox h-full">
                            <div className="timeline-box">
                                <Title level={5} style={{ marginBottom: 24}}>Usage History</Title>
                                <Timeline
                                    pending="updating..."
                                    className="timelinelist"
                                    reverse={reverse}
                                >
                                {data.map((t, index) => (
                                    <Timeline.Item key={index}>
                                        <Title level={5}>{moment(t.time).format('ddd MMM DD/MM | hh:mm a')}</Title>
                                        { t.status ?
                                            <Text style={{color: 'black'}}>On</Text>
                                            :
                                            <Text style={{color: 'black'}}>Off</Text>
                                        }
                                    </Timeline.Item>
                                ))}
                                </Timeline>
                                <Button
                                    type="primary"
                                    className="width-100"
                                    onClick={() => setReverse(!reverse)}
                                >
                                    {<MenuUnfoldOutlined />} REVERSE
                                </Button>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Card>
        </div>
    );
  
  }
  export default SwitchDetail;