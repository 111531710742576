/*!
*/
import {
  Row,
} from "antd";

function RuleEngine() {

  return (
    <>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
        </Row>
      </div>
    </>
  );
}

export default RuleEngine;
