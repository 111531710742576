import { useState, useEffect } from "react";

const storeKeys = {
  token: "token",
  propertyId: "propertyId",
  user: "user",
  colorTheme: "color-theme",
  selectedDevice: "selected-device",
  credentials: "credentials",
  doc: "device-doc",
  smartTheme: "smart-theme",
  haptic: "haptic",
  PN: "PN",
  homeVoltage: "home-voltage",
  property: "property",
  selectedHouse: "selected-house",
  devices: "devices",
  hikToken: "hik-token",
  fcmToken: "fcmToken",
  acTemperature: "acTemperature",
  analyticsStatus: "analyticsStatus",
  floor: "floorrmkey",
  lastRoom: "floorLastRoom",
  deviceConfig: "deviceConfigKey",
  offlineStatus: "offlineStatus",
  wifiCredenToken: "wifiCredenToken",
  accountCredenToken: "accountCredenToken",
  saveAllWifiNetToken: "saveAllWifiNetToken",
  bendingDeviceToken: "bendingDeviceToken",
  soundStateLocal: "soundStateLocal",
  environmentToken: "environmentToken",
};

//   const [localToken, setLocalToken] = useState();

const Keycloaktokens = {
  setToken(token) {
    localStorage.setItem(storeKeys.token, token);
  },
  getToken() {
    const items = localStorage.getItem(storeKeys.token);
    if (items) {
      return items ? items : null;
    }
  },
  removeToken() {
    localStorage.removeItem(storeKeys.token);
  },
};

const User = {
  setPropertyId(PropertyId) {
    localStorage.setItem(storeKeys.propertyId, PropertyId);
  },
  getPropertyId() {
    const items = localStorage.getItem(storeKeys.propertyId);
    if (items) {
      return items ? items : null;
    }
  },
  removePropertyId() {
    localStorage.removeItem(storeKeys.propertyId);
  },
  setUser(username) {
    localStorage.setItem(storeKeys.user, username);
  },
  getUser() {
    const items = localStorage.getItem(storeKeys.user);
    if (items) {
      return items ? items : null;
    }
  },
  removeUser() {
    localStorage.removeItem(storeKeys.user);
  },
};

const Settings = {};

const Device = {};

const Remote = {};

const Floor = {};
export { Keycloaktokens, User, Settings, Device, Remote, Floor };
