import React from "react";
import Keycloak from "keycloak-js";
import { useHistory } from "react-router-dom";
import { Keycloaktokens } from "../storage/store";

const Login = () => {
  // Refer the keyclock doc for authentication
  let initOptions = {
    url: "https://auth.hashh.io/auth/",
    realm: "HASHh",
    clientId: "hashh-dashboard",
    onLoad: "login-required",
    KeycloakResponseType: "code",
    scopes: ["openid", "profile"],
  };
  const keycloak = new Keycloak(initOptions);
  const navigate = useHistory();

  //Initiate the keycloack event and it push to the realm of keyclock to authentication
  keycloak
    .init({
      onLoad: initOptions.onLoad,
      KeycloakResponseType: "code",
      checkLoginIframe: false,
    })
    .then(
      (auth) => {
        if (!auth) {
          window.location.reload();
        } else {
          console.info("Authenticated");
          Keycloaktokens.setToken(keycloak.token);
          console.log(keycloak.token);

          navigate.push("dashboard");
        }
        setTimeout(() => {
          keycloak.updateToken(70).then(
            (refreshed) => {
              if (refreshed) {
                console.debug("Token refreshed" + refreshed);
              } else {
                console.warn(
                  "Token not refreshed, valid for " +
                    Math.round(
                      keycloak.tokenParsed.exp +
                        keycloak.timeSkew -
                        new Date().getTime() / 1000
                    ) +
                    " seconds"
                );
              }
            },
            () => {
              console.error("Authenticated Failed");
            }
          );
        }, 60000);
      },
      () => {
        console.error("Authenticated Failed");
      }
    );

  return <></>;
};
export default Login;
